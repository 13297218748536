//Hero Slider
(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const images = document.querySelectorAll('.bg-image');
        let currentImageIndex = getRandomIndex(images.length);

        function showImage(index) {
            images.forEach((image, i) => {
                if (i === index) {
                    image.classList.add('active');
                } else {
                    image.classList.remove('active');
                }
            });
        }

        function nextImage() {
            currentImageIndex = (currentImageIndex + 1) % images.length;
            showImage(currentImageIndex);
        }

        const interval = 6000;
        setInterval(nextImage, interval);

        function getRandomIndex(max) {
            return Math.floor(Math.random() * max);
        }

        showImage(currentImageIndex);
    });
})();
