import filtersLayout from '@js/vue/logic/filtersLayout';
// Services page -  display arrows & enable scroll; display list and map view; sort by price
// TODO - Replace with Splide.js
(function () {
    const sliderMenuArrows = document.querySelectorAll('.slider-menu-arrow');
    if (sliderMenuArrows.length) {
        const layout = filtersLayout();
        layout.initializeLayout();
        sliderMenuArrows.forEach((sliderArrow) => {
            sliderArrow.addEventListener('click', function (event) {
                event.preventDefault();
                const arrowDirection = sliderArrow.getAttribute('id');
                layout.horizontalScroll(arrowDirection);
            });
        });
    }
})();

// TODO - Replace with Splide.js
(function () {
    const sliderMenu = document.getElementById('sliderMenu');
    if (sliderMenu) {
        const leftArrow = document.getElementById('left');
        const rightArrow = document.getElementById('right');
        const scrollRight = sliderMenu.scrollWidth - sliderMenu.scrollLeft - sliderMenu.offsetWidth;
        let scrolling = false;

        window.addEventListener('load', function () {
            if (sliderMenu.scrollLeft > 0) leftArrow.classList.add('show');
            if (scrollRight > 0) rightArrow.classList.add('show');
        });

        sliderMenu.addEventListener('scroll', function () {
            scrolling = true;
        });

        setInterval(() => {
            if (scrolling) {
                scrolling = false;
                const updatedScrollRight = sliderMenu.scrollWidth - sliderMenu.scrollLeft - sliderMenu.offsetWidth;
                sliderMenu.scrollLeft > 0 ? leftArrow.classList.add('show') : leftArrow.classList.remove('show');
                updatedScrollRight > 0 ? rightArrow.classList.add('show') : rightArrow.classList.remove('show');
            }
        }, 300);
    }
})();

(function () {
    const displayListView = document.getElementById('display-list-view');
    if (displayListView) {
        const layout = filtersLayout();
        layout.initializeLayout();
        displayListView.addEventListener('click', function (event) {
            event.preventDefault();
            layout.displayListView();
        });
    }
})();

(function () {
    const displayMapView = document.getElementById('display-map-view');
    if (displayMapView) {
        const layout = filtersLayout();
        layout.initializeLayout();
        displayMapView.addEventListener('click', function (event) {
            event.preventDefault();
            layout.displayMapView();
        });
    }
})();

(function () {
    const displaySortTrigger = document.getElementById('display-sort');
    if (displaySortTrigger) {
        const displaySortMob = document.getElementById('sort-by-price-mob');
        displaySortTrigger.addEventListener('click', function (event) {
            event.preventDefault();
            if (displaySortMob.style.display === 'none') displaySortMob.style.display = 'flex';
            else displaySortMob.style.display = 'none';
        });
    }
})();

(function () {
    const sortSelect = document.getElementsByName('sort-by-price-value');
    if (sortSelect) {
        sortSelect.forEach((oneSortSelect) => {
            oneSortSelect.addEventListener('change', () => {
                dataLayer.push({
                    event: 'sortingApplied',
                    sortingType: oneSortSelect.value,
                });
                window.test.applyServiceFilters(false, oneSortSelect.value, true);
            });
        });
    }
})();

(function () {
    const resetSort = document.getElementsByName('reset-sort');
    if (resetSort) {
        resetSort.forEach((btnReset) => {
            btnReset.addEventListener('click', () => {
                window.test.applyServiceFilters(false, null, true);
                const resetSortFilter = document.getElementsByName('sort-by-price-value');
                resetSortFilter.forEach((oneOptionSelect) => {
                    oneOptionSelect.selectedIndex = 0;
                });
            });
        });
    }
})();
