// hamburger & sidebar
(function () {
    // sidebar height calculation
    window.addEventListener('load', () => {
        const setSidebarHeight = () => {
            const navbar = document.getElementById('navbar');
            const style = `calc(100vh - ${navbar.clientHeight}px)`;
            const sidebarContainer = document.querySelector('.sidebar-container');
            if (sidebarContainer) {
                sidebarContainer.style.height = style;
                sidebarContainer.style.top = navbar.clientHeight + 'px';
            }
        };

        const mediaQuery = window.matchMedia('(max-width: 1024px)');

        if (mediaQuery.matches) {
            setSidebarHeight();
        }

        mediaQuery.onchange = (e) => {
            if (e.matches) {
                setSidebarHeight();
            }
        };
    });
    const hamburger = document.querySelector('.hamburger-menu');
    if (hamburger) {
        hamburger.addEventListener('click', function () {
            if (hamburger.classList.contains('open')) {
                hamburger.classList.remove('open');
                document.body.classList.remove('prevent-scroll');
            } else {
                hamburger.classList.add('open');
                document.body.classList.add('prevent-scroll');
            }
        });
    }
})();

// logged in user dropdown on desktop
(function () {
    const userDetails = document.querySelector('.dropdown > .user-details');
    if (userDetails) {
        const navbarDropdown = document.querySelector('.navbar-dropdown');
        userDetails.addEventListener('click', function (event) {
            event.preventDefault();
            if (userDetails.classList.contains('open')) {
                userDetails.classList.remove('open');
                navbarDropdown.style.display = 'none';
            } else {
                userDetails.classList.add('open');
                navbarDropdown.style.display = 'block';
            }
        });
    }
})();
